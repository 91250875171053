import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`Our default server setup for projects with a Next.js frontend and a PHP based backend.`}<br parentName="p"></br>{`
`}{`We need an admin AWS account from the client, where we can setup up everything we need ourself. If thats not possible, the client needs to setup buckets and users for us.`}</p>
    <ul>
      <li parentName="ul">
        <Link to="/server-setup/1" mdxType="Link">1. AWS Buckets</Link>
      </li>
      <li parentName="ul">
        <Link to="/server-setup/2" mdxType="Link">2. AWS Users</Link>
      </li>
      <li parentName="ul">
        <Link to="/server-setup/3" mdxType="Link">3. ImgIX</Link>
      </li>
      <li parentName="ul">
        <Link to="/server-setup/4" mdxType="Link">4. Laravel Forge</Link>
      </li>
      <li parentName="ul">
        <Link to="/server-setup/5" mdxType="Link">5. Craft CMS</Link>
      </li>
      <li parentName="ul">
        <Link to="/server-setup/6" mdxType="Link">6. Next JS</Link>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      